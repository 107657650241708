import { defineStore } from 'pinia';

export const useI18nStore = defineStore('i18n', {
    state: () => ({
        selectedLocale: localStorage.getItem('selectedLocale') || ''
    }),
    actions: {
        setLocale(newLocale: string) {
            this.selectedLocale = newLocale;
            localStorage.setItem('selectedLocale', newLocale);
        }
    },
    getters: {
        hasLocale: (state) => !!state.selectedLocale,
    }
});
