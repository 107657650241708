import type { InjectionKey } from 'vue';
export interface AppConfig {
    robots: boolean;
    ga4: {
        enabled: boolean;
        id: string;
    };
    debug: boolean;
    api: {
        url: string;
    };
    sentry: {
        enabled: boolean;
        dsn: string;
    };
    forced_settings: {
        language: string;
        hide_header: boolean;
        hide_footer: boolean;
        branding: string;
        show_language_switcher: boolean;
    };
    iframe: {
        enabled: boolean;
        target_url: string;
    };
}

export const AppConfigInjectionKey: InjectionKey<AppConfig> = Symbol('$config');

export default async function loadConfig(): Promise<AppConfig> {
    const config: AppConfig = await fetch('/config/config.json').then(
        (response) => response.json()
    );
    return config;
}
