import {
    createRouter,
    createWebHistory,
    type Router,
    type RouteRecordRaw,
} from 'vue-router';
import HomeView from '../views/HomeView.vue';

import { type I18n, type Composer } from 'vue-i18n';
import { getCurrentCountry, getCurrentLanguage, setLocale, isLanguageCountryAllowed } from '.././libraries/i18n';

export function setupRouter(i18n: I18n): Router {
    const t = i18n.global.t as Composer<{}, {}, {}, true>['t'];
    const country = getCurrentCountry(i18n);
    const language = getCurrentLanguage(i18n);

    const routes: RouteRecordRaw[] = [
        {
            path: "/",
            redirect: `/${country}/${language}`
        },
        {
            path: "/:country(|pt|nl|be|)?/:language(|pt|nl|en|fr|)?/",
            children: [
                {
                    path: '',
                    name: 'home',
                    component: HomeView,
                },
                {
                    path: 'search-by-brand',
                    name: 'search-by-brand',
                    component: () => import('../views/SearchByBrandView.vue'),
                },
                {
                    path: 'search-by-feature',
                    name: 'search-by-feature',
                    component: () => import('../views/SearchByFeatureView.vue'),
                },
                {
                    path: 'suggested-cars',
                    name: 'suggested-cars',
                    component: () => import('../views/SuggestedCarsView.vue'),
                },
                {
                    path: 'suggested-cars/versions/:url_key',
                    name: 'suggested-cars-vehicles',
                    component: () =>
                        import('../views/SuggestedCarsVehicleVersionsView.vue'),
                    props: true,
                },
                {
                    path: 'compare-cars',
                    name: 'compare-cars',
                    component: () => import('../views/CompareCarsView.vue'),
                },
                window.location.href.includes('localhost')
                    ? {
                        path: 'styleguide',
                        name: 'styleguide',
                        component: () => import('../views/Styleguide.vue'),
                    }
                    : {
                        path: 'styleguide',
                        name: 'not-found',
                        component: () => import('../views/NotFound.vue'),
                        props: true,
                    },
                {
                    path: 'blog/:id',
                    name: 'blog',
                    component: () => import('../views/BlogView.vue'),
                },
                {
                    path: 'quote',
                    children: [
                        {
                            path: 'request',
                            name: 'quote_request',
                            component: () => import('../views/QuoteRequestForm.vue'),
                        },
                        {
                            path: 'request-edp',
                            name: 'quote_request-edp',
                            component: () => import('../views/QuoteRequestForm.vue'),
                            props: { edp: true, comment: false },
                        },
                        {
                            path: 'success/:generic?',
                            name: 'quote_success',
                            component: () => import('../views/QuoteSuccess.vue'),
                            props: true,
                        },
                        {
                            path: 'error',
                            name: 'quote_error',
                            component: () => import('../views/QuoteError.vue'),
                        },
                    ],
                },
                {
                    path: 'privacy-policy',
                    name: 'privacy',
                    component: () => import('../views/PrivacyView.vue'),
                },
                {
                    path: t('/terms-and-conditions'),
                    name: 'tc',
                    component: () => import('../views/TermsView.vue'),
                },
                {
                    path: 'vehicle/:url_key',
                    name: 'car_details',
                    component: () => import('../views/CarDetailsView.vue'),
                    props: true,
                },
                {
                    path: ':any(.*)',
                    name: 'not-found',
                    component: () => import('../views/NotFound.vue'),
                    props: true,
                },
            ]
        }
    ];

    const router = createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes,
    });

    router.beforeEach((to, from, next) => {
        const newLanguage = to.params.language as string;
        const newCountry = to.params.country as string;

        const currentCountry = getCurrentCountry(i18n);
        const currentLanguage = getCurrentLanguage(i18n);

        if (currentCountry == newCountry && currentLanguage == newLanguage) {
            return next();
        } else if (newLanguage && newCountry && isLanguageCountryAllowed(i18n, newLanguage, newCountry)) {
            setLocale(i18n, newLanguage, newCountry);
            return next({...to, params: {language: newLanguage, country: newCountry, ...to.params}});
        }

        return next({path: `/${country.toLocaleLowerCase()}/${language}${to.fullPath}`});
    })

    router.afterEach(() => {
        window.scrollTo(0, 0);
    });

    return router;
}

export default setupRouter;
