import type {I18n} from 'vue-i18n'
import {createI18n} from 'vue-i18n';
import enTranslations from '@/languages/en.json';
import ptTranslations from '@/languages/pt.json';
import frTranslations from '@/languages/fr.json';
import nlTranslations from '@/languages/nl.json';
import { useI18nStore } from '@/stores/i18n';

import {type Ref, toRef} from 'vue';

export const fallbackLocale = 'nl-EN';

const localeMapping: { [key: string]: any } = {
    fallbackLocale: enTranslations,
    'pt-PT': ptTranslations,
    'en-PT': enTranslations,
    'fr-BE': frTranslations,
    'nl-BE': nlTranslations,
    'nl-NL': nlTranslations,
    'en-NL': enTranslations,
};

let i18n: I18n | null = null;
export default async function initLocalization(forcedLanguage: string) {
    const i18nStore = useI18nStore();
    const storedLocale = i18nStore.selectedLocale;

    const locale = storedLocale || getBrowserLanguage() || forcedLanguage || fallbackLocale;

    i18n = createI18n({
        legacy: false,
        locale: locale,
        fallbackLocale: fallbackLocale,
        messages: localeMapping,
    });

    if (!i18nStore.selectedLocale && locale !== fallbackLocale) {
        i18nStore.setLocale(locale);
    }

    return i18n;
}

export function getI18nInstance(): I18n | null {
    if (i18n) {
        return i18n;
    }
    return null;
}

function getBrowserLanguage(): string | null {
    const navigatorLanguages = window.navigator.languages || [window.navigator.language];
    const supportedLocales = Object.keys(localeMapping);
    for (const lang of navigatorLanguages) {
        if (supportedLocales.includes(lang)) {
            return lang;
        }
        const langPrefix = lang.split('-')[0];
        const matchedLocale = supportedLocales.find((locale) =>
            locale.startsWith(langPrefix)
        );

        if (matchedLocale) {
            return matchedLocale;
        }
    }

    return null;
}

export function getAvailableLocales(i18n: I18n): string[] {
    return toRef(i18n.global, 'availableLocales').value as string[];
}

export function isLocaleAllowed(i18n: I18n, localeToCheck: string): boolean {
    const availableLocales = toRef(i18n.global, 'availableLocales').value as string[];
    return availableLocales.includes(localeToCheck);
}

export function isLanguageCountryAllowed(
    i18n: I18n,
    language: string,
    country: string
): boolean {
    const localeToCheck = `${language}-${country.toUpperCase()}`;
    return isLocaleAllowed(i18n, localeToCheck);
}

export function getCurrentCountry(i18n: I18n): string {
    const currentLocale = toRef(i18n.global, 'locale').value as string;
    const [, country = ''] = currentLocale.split('-');
    return country.toLowerCase();
}

export function getCurrentLanguage(i18n: I18n): string {
    const currentLocale = toRef(i18n.global, 'locale').value as string;
    const [language = ''] = currentLocale.split('-');
    return language;
}

export function getCurrentRegionalLocale(i18n: I18n): string {
    return toRef(i18n.global, 'locale').value as string;
}

export function setLocale(i18n: I18n, language: string, country: string): boolean {
    const newLocale = `${language}-${country.toUpperCase()}`;
    return setRegionalLocale(i18n, newLocale);
}

export function setRegionalLocale(i18n: I18n, newLocale: string): boolean {
    if (!isLocaleAllowed(i18n, newLocale)) {
        console.warn(`Locale ${newLocale} is not allowed.`);
        return false;
    }

    const localeRef = toRef(i18n.global, 'locale') as Ref<string>;
    localeRef.value = newLocale;

    const i18nStore = useI18nStore();
    i18nStore.setLocale(newLocale);

    return true;
}
