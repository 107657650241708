<script setup lang="ts">
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import globe from '@/assets/img/icons/globe.svg';
import chevrondown from '@/assets/img/icons/chevron-down.png';
import { useI18n } from "vue-i18n";
import { useRouter } from 'vue-router';
import { getI18nInstance, getCurrentCountry, getCurrentLanguage, setLocale, getAvailableLocales } from '.././libraries/i18n';

const { t } = useI18n();
const router = useRouter();
const i18n = getI18nInstance();

interface LanguageOption {
    label: string;
    abbreviation: string;
    value: string;
    url: string;
}

interface CountryOption {
    label: string;
    value: string;
    languages: LanguageOption[];
}

const availableLocalesArray = i18n ? getAvailableLocales(i18n) : [];

const countryOptions = ref<CountryOption[]>(
    Object.entries(
        availableLocalesArray.reduce<Record<string, string[]>>((acc, locale) => {
            const [language, country] = locale.split('-');
            if (!country) return acc;
            acc[country] = acc[country] || [];
            if (!acc[country].includes(language)) {
                acc[country].push(language);
            }
            return acc;
        }, {})
    ).map(([country, languages]) => ({
        label: t(`country.${country.toLowerCase()}`),
        value: country.toLowerCase(),
        languages: languages.map((language) => ({
            label: t(`language.${language}`),
            abbreviation: t(`abbreviation.${language}`),
            value: `${language}-${country.toUpperCase()}`,
            url: `/${country.toLowerCase()}/${language}`,
        })),
    }))
);

const selectedCountry = ref<CountryOption | null>(null);
const selectedLanguage = ref<LanguageOption | null>(null);

const selectCountry = (country: CountryOption) => {
    selectedCountry.value = country;
};

const handleLanguageSwitch = (language: LanguageOption) => {
    selectedLanguage.value = language;

    if (i18n) {
        const oldCountry = getCurrentCountry(i18n);
        const [lang, coun] = language.value.split('-');
        if (setLocale(i18n, lang, coun)) {
            router.replace({
                params: {
                    language: lang,
                    country: coun.toLowerCase()
                }
            }).then(() => {
                if (coun.toLowerCase() !== oldCountry.toLowerCase()) {
                    window.location.reload();
                } else {
                    dropdownOpen.value = false;
                }
            });
        }
    }
};

const availableLanguages = computed(() => {
    return selectedCountry.value ? selectedCountry.value.languages : [];
});

onMounted(() => {
    if (i18n) {
        const country = getCurrentCountry(i18n);
        const language = getCurrentLanguage(i18n);
        if (country && language) {
            const countryKey = country.toLowerCase();
            selectedCountry.value = countryOptions.value.find(c => c.value === countryKey) || null;
            const languageValue = `${language}-${country.toUpperCase()}`;
            selectedLanguage.value = availableLanguages.value.find(l => l.value === languageValue) || null;
        }
    }
});

const dropdownOpen = ref(false);
const toggleDropdown = () => {
    dropdownOpen.value = !dropdownOpen.value;
};

const languageSwitcherRef = ref<HTMLElement | null>(null);

const handleClickOutside = (event: MouseEvent) => {
    if (
        languageSwitcherRef.value &&
        !languageSwitcherRef.value.contains(event.target as Node)
    ) {
        dropdownOpen.value = false;
    }
};

watch(dropdownOpen, (newVal) => {
    if (newVal) {
        document.addEventListener('click', handleClickOutside);
    } else {
        document.removeEventListener('click', handleClickOutside);
    }
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
<template>
    <div class="language-switcher" ref="languageSwitcherRef">
        <div class="switcher-toggle" @click="toggleDropdown">
            <img :src="globe" alt="Globe Icon" class="globe-icon" />
            <span class="current-language">
              {{ selectedLanguage?.abbreviation || '---' }}
            </span>
            <img :src="chevrondown" alt="Chevron Down" class="chevron-icon" />
        </div>

        <div v-if="dropdownOpen" class="switcher-dropdown" :class="dropdownOpen ? 'open' : ''">
            <div class="switcher-column country">
                <div class="column-header">{{ $t('switcher.countries') }}</div>
                <ul class="column-list">
                    <li v-for="country in countryOptions" :key="country.value">
                        <label>
                            <input
                                type="radio"
                                name="country"
                                :value="country.value"
                                :checked="selectedCountry?.value === country.value"
                                @change="selectCountry(country)"
                            />
                            {{ country.label }}
                        </label>
                    </li>
                </ul>
            </div>

            <div class="switcher-column language">
                <div class="column-header">{{ $t('switcher.languages') }}</div>
                <ul class="column-list">
                    <li v-for="language in availableLanguages" :key="language.value">
                        <label>
                            <input
                                type="radio"
                                name="language"
                                :value="language.value"
                                :checked="selectedLanguage?.value === language.value"
                                @change="handleLanguageSwitch(language)"
                            />
                            {{ language.label }}
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
