import autoselctrLogo from '@/assets/img/autoselectr-logo-black.svg';
import produpressLogo from '@/assets/img/produpress-logo.svg';
import produpressFrLogo from '@/assets/img/produpress-fr-logo.svg';

interface BrandingConfig {
    logo: string;
    parent_link?: string;
    parent_link_about?: string;
    parent_link_privacy?: string;
    parent_link_terms?: string;
    parent_link_cookies?: string;
    parent_link_intellectual?: string;
    parent_link_contact?: string;
}

export const brandingConfig: Record<string, BrandingConfig> = {
    default: {
        logo: autoselctrLogo,
    },
    autoselectr: {
        logo: autoselctrLogo,
    },
    produpress: {
        logo: produpressLogo,
        parent_link: 'https://www.autogids.be/home.html',
        parent_link_about: 'https://www.produpress.be/?lang=nl',
        parent_link_privacy: 'https://www.autogids.be/privacy.html',
        parent_link_terms:
            'https://be-nl.produpress.k.elnino-production.com/algemene-voorwaarden',
        parent_link_cookies: 'https://www.autogids.be/cookies-verklaring.html',
        parent_link_intellectual:
            'https://autogids.be/intellectuele-rechten.html',
        parent_link_contact: 'https://abo.autogids.be/support',
    },
    produpress_fr: {
        logo: produpressFrLogo,
        parent_link: 'https://www.moniteurautomobile.be/accueil.html',
        parent_link_about: 'https://www.produpress.be/',
        parent_link_privacy:
            'https://www.moniteurautomobile.be/vie-privee.html',
        parent_link_terms:
            'https://be-fr.produpress.k.elnino-production.com/conditions-generales',
        parent_link_cookies:
            'https://www.moniteurautomobile.be/politique-cookies.html',
        parent_link_intellectual:
            'https://www.moniteurautomobile.be/droits-intellectuels.html',
        parent_link_contact: 'https://abo.moniteurautomobile.be/support',
    },
};
